import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  input,
} from '@angular/core';

import { BreakpointDirective } from '@smw/front-utils';

@Component({
  standalone: true,
  selector: 'smw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  imports: [BreakpointDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  constructor(private el: ElementRef) {}

  color = input<'primary' | 'white'>('white');

  @HostBinding('style.background-color') get backgroundColor(): string {
    if (this.color() === 'white') {
      return 'rgba(0,0,0,0)';
    }
    return `var(--${this.color()})`;
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    this.el.nativeElement.style.borderBottom =
      window.scrollY > 50 ? '1px solid rgba(0,0,0,0.1)' : '';
  }
}
