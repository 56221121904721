import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable, of } from 'rxjs';

import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileGuard {
  constructor(
    private profileService: ProfileService,
    private router: Router,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    if (this.profileService.isLoaded()) {
      return of(true);
    }

    return this.profileService.loadProfile().pipe(
      map(() => {
        if (this.profileService.isLoaded()) {
          return true;
        }

        return this.router.createUrlTree(['/workoscope']);
      }),
    );
  }
}
