export type AnalyticsEvent =
  | PageOpened
  | PageClosed
  | SignedUp
  | SignedIn
  | PasswordLost
  | ActionAdded
  | ActionModified
  | ActionCancelled
  | ActionCompleted
  | DocumentDownloaded
  | MotivationDone
  | CheckUpDone
  | TestCompleted
  | SolutionAddedToFavorites
  | SolutionFiltered
  | SolutionLinkOpened
  | ActionCreatedFromSolution
  | ConversationCreated
  | ConversationOpened
  | ConversationEdited
  | ConversationUpdated
  | ConversationShared;

// todo: unsure what this is used for, refactor
export type EventId = AnalyticsEvent['id'];

export enum EventIdentifier {
  PageOpened = 'pageOpened',
  PageClosed = 'pageClosed',
  PasswordLost = 'passwordLost',
  ActionAdded = 'actionAdded',
  ActionCompleted = 'actionCompleted',
  ActionCancelled = 'actionCancelled',
  ActionModified = 'actionModified',
  DocumentDownloaded = 'documentDownloaded',
  TestCompleted = 'testCompleted',
  MotivationDone = 'motivationDone',
  CheckUpDone = 'checkUpDone',
  UserSignedUp = 'userSignedUp',
  UserSignedIn = 'userSignedIn',
  SolutionAddedToFavorites = 'solutionAddedToFavorites',
  ActionCreatedFromSolution = 'actionCreatedFromSolution',
  SolutionLinkOpened = 'solutionLinkOpened',
  SolutionFiltered = 'solutionFiltered',
  ConversationCreated = 'conversationCreated',
  ConversationOpened = 'conversationOpened',
  ConversationEdited = 'conversationEdited',
  ConversationUpdated = 'conversationUpdated',
  ConversationShared = 'conversationShared',
}

export type PageOpened = {
  id: EventIdentifier.PageOpened;
  data: {
    url: string;
    previousUrl: string;
  };
};

export type PageClosed = {
  id: EventIdentifier.PageClosed;
  data: {
    url: string;
  };
};

type PasswordLost = {
  id: EventIdentifier.PasswordLost;
  data: {
    email: string;
  };
};

type ActionAdded = {
  id: EventIdentifier.ActionAdded;
  data?: void;
};

type ActionCompleted = {
  id: EventIdentifier.ActionCompleted;
  data?: void;
};

type ActionCancelled = {
  id: EventIdentifier.ActionCancelled;
  data?: void;
};

type ActionModified = {
  id: EventIdentifier.ActionModified;
  data?: void;
};

type DocumentDownloaded = {
  id: EventIdentifier.DocumentDownloaded;
  data: {
    page: string;
    documentName: string;
  };
};

type TestCompleted = {
  id: EventIdentifier.TestCompleted;
  data?: void;
};

type MotivationDone = {
  id: EventIdentifier.MotivationDone;
  data?: void;
};

type CheckUpDone = {
  id: EventIdentifier.CheckUpDone;
  data?: void;
};

type SignedUp = {
  id: EventIdentifier.UserSignedUp;
  data?: void;
};

type SignedIn = {
  id: EventIdentifier.UserSignedIn;
  data?: void;
};

type SolutionAddedToFavorites = {
  id: EventIdentifier.SolutionAddedToFavorites;
  data: {
    solutionId: string;
  };
};

type ActionCreatedFromSolution = {
  id: EventIdentifier.ActionCreatedFromSolution;
  data: {
    solutionId: string;
  };
};

type SolutionLinkOpened = {
  id: EventIdentifier.SolutionLinkOpened;
  data: {
    solutionId: string;
  };
};

type SolutionFiltered = {
  id: EventIdentifier.SolutionFiltered;
  data: {
    filter: 'topMotivation' | 'lowMotivation' | 'profile';
  };
};

type ConversationCreated = {
  id: EventIdentifier.ConversationCreated;
  data: {
    conversationType: string;
    recurrence: string;
  };
};

type ConversationOpened = {
  id: EventIdentifier.ConversationOpened;
  data: {
    conversationType: string;
    recurrence: string;
  };
};

type ConversationEdited = {
  id: EventIdentifier.ConversationEdited;
  data: {
    typeChanged: boolean;
    recurrenceChanged: boolean;
    dateChanged: boolean;
    attendeesChanged: boolean;
    target: 'next' | 'all';
  };
};

type ConversationUpdated = {
  id: EventIdentifier.ConversationUpdated;
  data: {
    conversationType: string;
    recurrence: string;
    withAttendees: boolean;
    section: string;
  };
};

type ConversationShared = {
  id: EventIdentifier.ConversationShared;
  data: {
    conversationType: string;
    recurrence: string;
  };
};
