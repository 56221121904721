import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, NgModel, UntypedFormGroup } from '@angular/forms';

export interface SelectUIOption {
  label: string;
  value: string;
}

@Component({
  selector: 'smw-input-select-ui',
  templateUrl: './input-select-ui.component.html',
  styleUrls: ['./input-select-ui.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputSelectUiComponent {
  @Input() placeholder?: string;
  @Input() selectValue?: string;
  @Input() options!: SelectUIOption[];

  /** Form context */
  @Input() formGroup?: UntypedFormGroup;
  @Input() controlName?: string;
  @Input() errorTpl?: TemplateRef<{ $implicit: AbstractControl | NgModel }>;
  /** Form context */

  @Output() selectValueChange = new EventEmitter<string>();
}
