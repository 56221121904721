<nav class="top-bar">
  <div class="container flex items-center justify-between">
    <div class="back-wrapper">
      <ng-content selected="[back]"></ng-content>
    </div>

    <div class="flex items-center gap-4">
      <div class="secondaries">
        <ng-content select="[secondaries]"></ng-content>
      </div>
      <ng-content select="[primary]" *breakpoint="'sm'"></ng-content>
    </div>
  </div>
</nav>
