import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { HotkeyModule } from 'angular2-hotkeys';

import { AnalyticsModule } from '@smw/front-analytics';
import { ApiClientModule } from '@smw/front-api-client';
import { AngularTranslateModule } from '@smw/front-translate';
import { IconCacheInterceptor } from '@smw/front-ui';
import { AngularCommonUtilsModule, BreakpointDirective } from '@smw/front-utils';
import { AuthInterceptor } from '@so-many-ways/angular-authentication';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    PortalModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HotkeyModule.forRoot(),
    AngularTranslateModule,
    AppRoutingModule,

    AnalyticsModule,

    ApiClientModule,
    // Utils
    AngularCommonUtilsModule,

    BreakpointDirective,
  ],
  providers: [
    // The import above seem to use ErrorModule from traxion libs but these doesnt seem to be used nor working. Maybe it relies on env vars being defined
    // But for better control I am fallbacking to a custom implementation without traxion
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // There are currently to many unhanled exception, we can't rely on this as user will get spam on most pages
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
        // We must have something here otherwise linter complains for empty function.
        // Not sure what are the implication of removing the empty function for the factory here.
        // Also we'd like to keep this linting restriction, this case is just required by Sentry
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IconCacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
