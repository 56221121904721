import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { ClientPlugins } from '@so-many-ways/models';

@Injectable()
export class PluginsService {
  pluginClient$: Observable<ClientPlugins[]>;

  constructor(private http: HttpClient) {
    this.pluginClient$ = this.http
      .get<ClientPlugins[]>(`/api/plugins`)
      .pipe(catchError(() => of([])));
  }

  getPluginsList$(): Observable<ClientPlugins[]> {
    return this.pluginClient$;
  }

  containPlugin(plugin: ClientPlugins): Observable<boolean> {
    return this.pluginClient$.pipe(map((listPlugins) => listPlugins?.includes(plugin)));
  }
}
