import { HttpClient } from '@angular/common/http';
import { computed, Inject, Injectable, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, filter, Observable, Subject, takeUntil, tap } from 'rxjs';

import { ANALYTICS, Analytics } from '@smw/front-analytics';
import { isDefined } from '@smw/front-utils';
import { User } from '@so-many-ways/models';

@Injectable({ providedIn: 'root' })
export class SessionService implements OnDestroy {
  private me$ = new BehaviorSubject<User | null>(null);
  private unsubscribe$ = new Subject<void>();

  // TODO : update other components that compute these property so there's only one source of truth
  // for the user roles assesment logic
  user = toSignal(this.me());

  firstname = computed(() => this.user()?.firstname);
  isManager = computed(() => this.user()?.roles.includes('manager') ?? false);

  constructor(
    private http: HttpClient,
    @Inject(ANALYTICS) private analytics: Analytics,
  ) {
    // The session component is responsible for notifying all analytics of the user's identity (which may be available upon login or upon connection of a logged customer)
    // This can potentially be called several times during the same app lifecycle, and that's ok.
    // This will avoid edge cases of relying only on the sign-up/register event, as well as making sure user data stays up to date when its updated after registrations
    this.me()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((me) => {
        if (me) {
          this.analytics.identify(me.id, {
            email: me.email,
            tenant: me.client?.subDomain,
            roles: ['user'],
            firstname: me.firstname,
            lastname: me.lastname ?? undefined,
            businessService: me.businessServiceId ?? undefined,
            jobResponsabilities: me.jobResponsabilities ?? undefined,
            newsletterConsent: me.newsletterConsent ?? false,
            clientId: me.client?.id,
            clientName: me.client?.name,
            isRealUser: me.isRealUser,
          });

          Sentry.setUser({username: me.firstname, email: me.email, id:me.id})
          // We could register a real analytics service for userback but since we only need the idenfity its a bit overkill
          // Also there's a typescript SDK, but similarly it's a bit overkill and make it rely on the app initialisation, while pure html integration make the feedback tool usable even if the app can't bootstrap // there's a code issue on in our stack.
          // Also, we will likely stop using userback very soon
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const ub = (<any>window).Userback
          if (ub && ub.identify) {
            ub.identify(me.id, {
              name: `${me.firstname} ${me.lastname}`,
              email: me.email,
              account_id: me.client?.id,
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadUser(): Observable<User> {
    return this.http.get<User>('/api/user').pipe(tap((user) => this.me$.next(user)));
  }

  me(): Observable<User> {
    return this.me$.asObservable().pipe(filter(isDefined));
  }

  clear(): void {
    this.me$.next(null);
  }
}
