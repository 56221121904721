import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { AppConfigService } from '@smw/front-utils';
import { JobResponsibility } from '@so-many-ways/models';

import { Analytics, AnalyticsUserProfile } from './analytics';
import { AnalyticsEvent } from './events';

@Injectable({ providedIn: 'root' })
export class SegmentAnalytics implements Analytics {
  private segment!: AnalyticsBrowser;
  private userProfile?: AnalyticsUserProfile;

  constructor(
    http: HttpClient,
    private configService: AppConfigService,
  ) {
    this.configService.getConfig().subscribe((config) => {
      this.segment = AnalyticsBrowser.load(
        {
          writeKey: config.SEGMENT_WRITE_KEY,
          cdnURL: config.SEGMENT_CDN_URL,
        },
        {
          integrations: {
            'Segment.io': {
              apiHost: config.SEGMENT_API_HOST,
              protocol: 'https',
            },
          },
        },
      );
    });
  }

  identify(userId: string, userProfile: AnalyticsUserProfile): void {
    this.userProfile = userProfile;
    let jobResponsibilities = '';

    switch (userProfile.jobResponsabilities) {
      case JobResponsibility.COACH:
        jobResponsibilities = 'persona_4';
        break;
      case JobResponsibility.DIRECTION:
        jobResponsibilities = 'persona_2';
        break;
      case JobResponsibility.OTHER:
        jobResponsibilities = 'persona_5';
        break;
      case JobResponsibility.RH:
        jobResponsibilities = 'persona_1';
        break;
      default:
        jobResponsibilities = '';
        break;
    }

    this.segment.identify(userId, {
      email: userProfile.email,
      firstName: userProfile.firstname,
      lastName: userProfile.lastname,
      company: {
        name: userProfile.tenant,
      },
      jobResponsibilities,
      businessService: userProfile.businessService,
      newsletterConsent: !!userProfile.newsletterConsent ?? false,
    });
    if (userProfile.tenant) {
      this.segment.group(userProfile.tenant);
    }
  }

  track(event: AnalyticsEvent): void {
    this.segment.track(event.id, event.data ?? undefined, {
      traits: {
        email: this.userProfile?.email,
        company: {
          name: this.userProfile?.tenant,
        },
      },
    });
  }

  disconnectIdentity(): void {
    // Not required
  }

  startTimer(): void {
    // Not required
  }
}
