import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

import {
  ButtonUiComponent,
  InputRadioUiComponent,
  InputSelectUiComponent,
  InputSingleCheckboxUiComponent,
  InputTextareaUiComponent,
  InputTextUiComponent,
} from './components';

const components = [
  ButtonUiComponent,
  InputTextUiComponent,
  InputSelectUiComponent,
  InputSingleCheckboxUiComponent,
  InputRadioUiComponent,
  InputTextareaUiComponent,
];

const ngZorroModules = [
  NzFormModule,
  NzInputModule,
  NzSelectModule,
  NzCheckboxModule,
  NzRadioModule,
  NzDropDownModule,
  NzStepsModule,
  NzToolTipModule,
  NzProgressModule,
];

@NgModule({
  imports: [RouterModule, CommonModule, FormsModule, ReactiveFormsModule, ...ngZorroModules],
  declarations: [...components],
  exports: [...components, ...ngZorroModules],
})
export class AngularCommonUiModule {}
