import { HttpParams } from '@angular/common/http';

export class HttpParamsBuilder {
  private params = new HttpParams();

  append(param: string, value: string | number | boolean | undefined): this {
    if (value) {
      this.params = this.params.append(param, value);
    }
    return this;
  }

  build(): HttpParams {
    return this.params;
  }
}
