/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';

import { AnonymousGuard, LoggedInGuard } from '@so-many-ways/angular-authentication';
import { MobilityGuard } from '@so-many-ways/angular-mobility';
import { ProfileGuard } from '@so-many-ways/angular-profile';

const routes: Routes = [
  {
    path: 'authentification',
    canActivate: [AnonymousGuard],
    loadChildren: () =>
      import('@so-many-ways/angular-authentication').then((m) => m.AUTHENTICATION_ROUTES),
  },
  {
    path: 'tableau-de-bord',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('@so-many-ways/angular-dashboard').then((m) => m.DASHBOARD_ROUTES),
  },
  {
    path: 'mon-compte',
    canActivate: [LoggedInGuard],
    loadComponent: () =>
      import('@so-many-ways/angular-account').then((m) => m.AccountPageComponent),
  },
  {
    path: 'workoscope',
    canActivate: [LoggedInGuard],
    loadComponent: () =>
      import('@so-many-ways/angular-survey').then((m) => m.ProfileQuestionnairePageComponent),
  },
  {
    path: 'conversations',
    data: { checkUnavailability: true },
    loadChildren: () =>
      import('@so-many-ways/angular-conversations').then((m) => m.CONVERSATIONS_ROUTES),
  },
  {
    path: 'questionnaire',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('@so-many-ways/angular-simple-survey').then((m) => m.SIMPLE_SURVEY_ROUTES),
  },
  {
    path: 'mon-profil-workoscope',
    canActivate: [LoggedInGuard, ProfileGuard],
    loadComponent: () =>
      import('@so-many-ways/angular-profile').then((m) => m.ProfilePageComponent),
    loadChildren: () => import('@so-many-ways/angular-profile').then((m) => m.PROFILE_ROUTES),
  },
  {
    path: 'motivations',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('@so-many-ways/angular-motivations').then((m) => m.MOTIVATIONS_ROUTES),
  },
  {
    path: 'check-up',
    canActivate: [LoggedInGuard],
    loadChildren: () => import('@so-many-ways/angular-checkup').then((m) => m.CHECKUP_ROUTES),
  },
  {
    path: 'solutions',
    canActivate: [LoggedInGuard],
    data: { checkUnavailability: true },
    loadChildren: () => import('@so-many-ways/angular-solutions').then((m) => m.SOLUTIONS_ROUTES),
  },
  {
    path: 'actions',
    canActivate: [LoggedInGuard],
    data: { checkUnavailability: true },
    loadChildren: () => import('@so-many-ways/angular-actions').then((m) => m.ACTIONS_ROUTES),
  },
  {
    path: 'parcours-mobilite',
    canActivate: [LoggedInGuard, MobilityGuard],
    data: { checkUnavailability: true },
    loadChildren: () => import('@so-many-ways/angular-mobility').then((m) => m.MOBILITY_ROUTES),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tableau-de-bord',
  },
  {
    path: '**',
    loadComponent: () => import('@smw/front-404').then((m) => m.NotFoundComponent),
  },
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, { bindToComponentInputs: true, scrollPositionRestoration: 'top' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
