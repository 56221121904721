import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PluginsService } from '@smw/front-utils';

@Injectable({ providedIn: 'root' })
export class AccessValidator {
  constructor(private pluginService: PluginsService) {}

  validate(): Observable<boolean> {
    return this.pluginService.containPlugin('mobility');
  }
}
