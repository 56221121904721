import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticationService } from '../services';
import { CallbackUrlService } from '../services/callback-url.service';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private callbackUrlService: CallbackUrlService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    route;

    return this.authService.isAuthenticated().pipe(
      map((isAuthenticated) => {
        if (isAuthenticated) {
          return true;
        }

        this.callbackUrlService.register(state.url);
        return this.router.createUrlTree(['/authentification'], {
          queryParamsHandling: 'merge',
          queryParams: route.queryParams,
        });
      }),
    );
  }
}
