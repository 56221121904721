import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  LOCALE_ID,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, Event as RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';
import { filter, pairwise, Subject, takeUntil } from 'rxjs';

import { ANALYTICS, Analytics, EventIdentifier } from '@smw/front-analytics';
import { NotificationService } from '@smw/front-ui';
import { PageService } from '@smw/front-utils';
import { SessionService } from '@so-many-ways/angular-authentication';

function isNavigationEnd(event: RouterEvent): event is NavigationEnd {
  return event instanceof NavigationEnd;
}

@Component({
  selector: 'smw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  page$ = this.pageService.getPage();

  constructor(
    @Inject(ANALYTICS) private analytics: Analytics,
    @Inject(LOCALE_ID) locale: string,
    @Inject(DOCUMENT) document: Document,
    private nzI18n: NzI18nService,
    private pageService: PageService,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private router: Router,
    private hotkeysService: HotkeysService,
    private notification: NotificationService,
    private sessionService: SessionService,
  ) {
    document.documentElement.lang = locale;
  }

  ngOnInit(): void {
    this.setupI18n();
    this.trackNavigationEvents();
    // Note : intercom @Types are global, no need to import them

    this.sessionService.loadUser().subscribe();

    this.page$.pipe(takeUntil(this.unsubscribe$)).subscribe((page) => {
      if (page.title) {
        this.titleService.setTitle(<string>this.translateService.instant(page.title));
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupI18n(): void {
    this.nzI18n.setLocale(fr_FR);
    this.translateService.addLangs(['fr', 'key']);

    this.hotkeysService.add(
      new Hotkey('ctrl+alt+l', (): boolean => {
        if (this.translateService.currentLang === 'key') {
          this.translateService.use('fr');
        } else {
          this.notification.info({
            message: `Appuyez sur Ctrl + Alt + L pour afficher les textes.`,
          });
          this.translateService.use('key');
        }
        this.changeDetectorRef.markForCheck();

        return false; // Prevent bubbling
      }),
    );
    this.changeDetectorRef.markForCheck();
  }

  private trackNavigationEvents(): void {
    this.router.events
      .pipe(filter(isNavigationEnd), pairwise())
      .subscribe((event: NavigationEnd[]) => {
        const previousNavigation = event[0];
        const currentNavigation = event[1];

        if (previousNavigation) {
          this.analytics.track({
            id: EventIdentifier.PageClosed,
            data: { url: previousNavigation.url },
          });
        }
        this.analytics.startTimer(EventIdentifier.PageClosed);

        this.analytics.track({
          id: EventIdentifier.PageOpened,
          data: {
            url: currentNavigation.url,
            previousUrl: previousNavigation.url,
          },
        });
      });
  }
}
