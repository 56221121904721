import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
// import appConfig from './assets/app-config.json'
import { environment } from './environments/environment';
import packageJson from '../package.json';
const { version } = packageJson;
if (environment.production) {
  enableProdMode();
}

const envName = environment.production ? 'production' : 'dev';

Sentry.init({
  dsn: 'https://e23c0a9dbeeb497186a812fa2e6c3743@o1177722.ingest.sentry.io/4505330183962624',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      networkRequestHeaders: ['Cache-Control'],
      networkResponseHeaders: ['Referrer-Policy'],
      // We don't have time to customize the masking setting for now, so we revert to showing everything
      // Except input to avoid blatant pricacy violations
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: true,
    }),
    // Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: 'system',
    // }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Note: would be better to have a real "envName" env variable defined so we can distingish sentry reports between staging/preprod/dev etc
  tracePropagationTargets: ['localhost', /.*/],
  environment: envName,
  release: version,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ub = (<any>window).Userback;
if (ub) {
  //docs.userback.io/docs/attach-custom-data
  ub.custom_data = {
    app_version: version,
  };
}

platformBrowserDynamic().bootstrapModule(AppModule).catch(console.error);
