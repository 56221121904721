import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  TemplateRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { OutsideClickDirective } from '@smw/front-utils';

import { Overlay } from './overlay';
import { CheckboxComponent } from '../form';

export type DropdownItem<T> = {
  value: T;
  label?: string;
  disabled?: boolean;
};

@Component({
  selector: 'smw-dropdown',
  standalone: true,
  imports: [CommonModule, TranslateModule, CheckboxComponent, OutsideClickDirective, FormsModule],
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent<T> {
  options = input.required<DropdownItem<T>[]>();
  multiple = input(false);
  selectedOptions = input<Set<T | T[keyof T]>>();
  comparisonKey = input<keyof T>();
  customItemLabelTemplate = input<TemplateRef<unknown>>();
  emptyMessage = input<string>();

  itemSelected = output<T>();
  closed = output();

  isEmpty = computed(() => this.options().length === 0);

  constructor(private overlay: Overlay) {}

  isChecked(option: DropdownItem<T>): boolean {
    const selectedOptions = this.selectedOptions();
    if (!selectedOptions || selectedOptions.size === 0) {
      return false;
    }

    const comparisonKey = this.comparisonKey();
    return selectedOptions.has(comparisonKey ? option.value[comparisonKey] : option.value);
  }

  onClick(selection: T): void {
    if (this.multiple()) {
      return;
    }

    this.itemSelected.emit(selection);
    this.overlay.close();
  }
}
