import { Injectable } from '@angular/core';

import { Analytics, AnalyticsUserProfile } from './analytics';
import { AnalyticsEvent } from './events';
import { IntercomAnalytics } from './intercom.analytics';
import { SegmentAnalytics } from './segment.analytics';

@Injectable({
  providedIn: 'root',
})
export class AllAnalytics implements Analytics {
  constructor(
    private segment: SegmentAnalytics,
    private intercomAnalytics: IntercomAnalytics,
  ) {}

  identify(userId: string, userProfile: AnalyticsUserProfile): void {
    this.segment.identify(userId, userProfile);
    this.intercomAnalytics.identify(userId, userProfile);
  }

  disconnectIdentity(): void {
    this.segment.disconnectIdentity();
    this.intercomAnalytics.disconnectIdentity();
  }

  track(event: AnalyticsEvent): void {
    this.segment.track(event);
    this.intercomAnalytics.track(event);
  }

  startTimer(trackedEventId: string): void {
    this.segment.startTimer();
    this.intercomAnalytics.startTimer(trackedEventId);
  }
}
