<div class="backdrop" (click)="cancel.emit()"></div>
<div class="modal-overlay">
  <header>
    <p class="title-sm text-black mb-5">{{ title() }}</p>
    @if (showClose()) {
      <button type="button" (click)="cancel.emit()">
        <smw-icon icon="close"></smw-icon>
      </button>
    }
  </header>

  <div class="content">
    <ng-content></ng-content>
  </div>

  <footer>
    <button smw-button kind="outlined" (click)="cancel.emit()">
      {{ cancelLabel() ?? ('cancel' | translate) }}
    </button>
    <button smw-button kind="filled" (click)="confirm.emit()">
      {{ confirmLabel() ?? ('confirm' | translate) }}
    </button>
  </footer>
</div>
