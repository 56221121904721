import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { map, Observable, of, take, tap } from 'rxjs';

import { Profile } from '@so-many-ways/rest-dtos';

@Injectable({ providedIn: 'root' })
export class ProfileService {
  constructor(private http: HttpClient) {}

  profile = signal<Profile | undefined>(undefined);
  isLoading = signal(false);

  isLoaded = computed(() => !this.isLoading() && !!this.profile());
  updatedAt = computed(() => this.profile()?.updatedAt);
  majorMode = computed(() => this.profile()?.major);
  scores = computed(() => {
    const profile = this.profile();
    if (!profile) {
      return [];
    }

    return profile.modes.map((item) => ({
      mode: item.mode,
      score: item.score,
      isMajor: item.mode === profile.major.mode,
      isMinor: item.mode === profile.minor.mode,
    }));
  });

  loadProfile(): Observable<void> {
    const profile = this.profile();
    if (profile) {
      return of();
    }

    this.isLoading.set(true);

    return this.http.get<Profile>('/api/profile').pipe(
      take(1),
      tap((profile) => {
        this.profile.set(profile);
        this.isLoading.set(false);
      }),
      map(() => {}),
    );
  }
}
