<ng-container *ngIf="formGroup && controlName; else SimpleContext">
  <nz-form-item>
    <nz-form-control [formGroup]="formGroup" [nzErrorTip]="errorTpl">
      <nz-select
        class="classic"
        [nzPlaceHolder]="placeholder ? placeholder : null"
        [ngModel]="selectValue"
        (ngModelChange)="selectValueChange.emit($event)"
        [nzSuffixIcon]="iconDropdown"
        nzDropdownClassName="input-select"
        [formControlName]="controlName"
      >
        <nz-option
          *ngFor="let option of options"
          [nzValue]="option.value"
          [nzLabel]="option.label"
        ></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</ng-container>

<ng-template #SimpleContext>
  <nz-select
    class="classic"
    [nzPlaceHolder]="placeholder ? placeholder : null"
    [ngModel]="selectValue"
    (ngModelChange)="selectValueChange.emit($event)"
    [nzSuffixIcon]="iconDropdown"
    nzDropdownClassName="input-select"
  >
    <nz-option
      *ngFor="let option of options"
      [nzValue]="option.value"
      [nzLabel]="option.label"
    ></nz-option>
  </nz-select>
</ng-template>

<ng-template #iconDropdown>
  <i class="icon-dropdown"></i>
</ng-template>
