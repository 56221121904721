<ng-container *ngIf="formGroup && controlName; else SimpleContext">
  <nz-form-item>
    <nz-form-control [formGroup]="formGroup">
      <nz-radio-group
        class="classic"
        [nzSize]="size"
        [ngModel]="radioValue"
        (ngModelChange)="radioValueChange.emit($event)"
      >
        <ng-container *ngFor="let answer of answers; let i = index">
          <div class="option">
            <label nz-radio [nzValue]="answer.value">{{ answer.label }}</label>
            <span *ngIf="style === 'survey'" class="number">{{ i + 1 }}</span>
          </div>
        </ng-container>
      </nz-radio-group>
    </nz-form-control>
  </nz-form-item>
</ng-container>

<ng-template #SimpleContext>
  <nz-radio-group
    class="classic"
    [nzSize]="size"
    [ngModel]="radioValue"
    (ngModelChange)="radioValueChange.emit($event)"
  >
    <ng-container *ngFor="let answer of answers; let i = index">
      <div class="option">
        <label nz-radio [nzValue]="answer.value">{{ answer.label }}</label>
        <span *ngIf="style === 'survey'" class="number">{{ i + 1 }}</span>
      </div>
    </ng-container>
  </nz-radio-group>
</ng-template>
