import { BusinessService } from './business-service';
import { Client } from './client';
import { MobilityProgress } from './mobility-progress';
import { SurveyResult } from './survey-result';
import { WorkoscopeCustomMode } from './workoscope-custom-mode';
import { WorkoscopeModeField } from './workoscope-mode-field';
import { WorkoscopeResult } from './workoscope-result';
import { UserGender, UserModuleAccess, UserRole } from '../enums';

export enum JobResponsibility {
  COACH = 'Je suis coach, facilitateur ou consultant',
  DIRECTION = "Je suis responsable d'une équipe, d'un département, d'une entreprise",
  RH = 'RH : vous recrutez, formez, ou prenez soin des gens',
  OTHER = 'Rien de tout cela',
}

export type User = {
  id: string;
  createdAt: string;
  firstname: string;
  lastname?: string;
  email: string;
  gender?: UserGender;
  birthYear?: string;
  postalCode?: string;
  degree?: string;
  workSituation?: string;
  morale?: string;
  businessLine?: string;
  yearsOfExperience?: string;
  numberOfEmployers?: string;
  numberOfJobs?: string;
  otherCompanyName?: string;
  phoneNumber?: string;
  knownHow?: string;
  socioProfessionalCategory?: string;
  jobResponsabilities?: JobResponsibility;
  newsletterConsent: boolean;
  isRealUser?: boolean;
  clientId?: string;
  client?: Client;
  moduleAccess: UserModuleAccess[];
  businessServiceId?: string;
  businessService?: BusinessService;
  mobility?: MobilityProgress[];
  surveyResults?: SurveyResult[];
  workoscopeCustomMode?: WorkoscopeCustomMode[];
  workoscopeModeField?: WorkoscopeModeField[];
  workoscopeResult?: WorkoscopeResult[];
  roles: UserRole[];
};
