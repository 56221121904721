/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'button[smw-button], a[smw-button]',
  standalone: true,
  imports: [CommonModule, IconComponent],
  template: `
    <smw-icon *ngIf="icon" [icon]="icon"></smw-icon>
    <ng-container *ngIf="kind !== 'icon'">
      @if (loading) {
        <smw-icon
          [icon]="invert_loading ? 'loading-invert' : 'loading'"
          class="!w-14 absolute"
        ></smw-icon>
      }
      <div [ngClass]="loading ? 'opacity-0' : ''">
        <ng-content></ng-content>
      </div>
    </ng-container>
  `,
  styleUrls: ['./button.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  constructor(private elementRef: ElementRef<HTMLButtonElement | HTMLLinkElement>) {
    const element = this.elementRef.nativeElement;
    if (element.tagName.toLowerCase() === 'button') {
      this.elementRef.nativeElement.setAttribute('type', 'button');
    }
  }

  @Input() kind:
    | 'filled'
    | 'filled-inversed'
    | 'outlined'
    | 'outlined-white'
    | 'text'
    | 'icon'
    | 'tertiary' = 'filled';

  @Input() loading: boolean = false;
  @Input() invert_loading: boolean = false;

  @Input() icon?: string;

  @HostBinding('class') get classes(): string {
    return this.kind;
  }
}
