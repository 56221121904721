/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class AppConfigService {
  private config: any;
  private config$: Observable<any>;

  constructor(private http: HttpClient) {
    this.config$ = this.http.get<any>('/assets/app-config.json');
    this.config$.subscribe((config) => {
      this.config = config;
    });
  }

  getConfig(){
    return this.config$;
  }
}
