import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { BehaviorSubject, Observable } from 'rxjs';

import { PageInterface } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private page$ = new BehaviorSubject<PageInterface>({
    // TODO: this doesnt work, it shows page-title-default in the tab title, annoying. Update with real translation service?
    title: marker('page-title-default'),
  });

  /**
   * Set the current page header
   *
   * @param page
   */
  setPage(page: PageInterface): void {
    this.page$.next(page);
  }

  /**
   * Retrieve the current page as Observable
   *
   * @return {Observable<PageInterface>}
   */
  getPage(): Observable<PageInterface> {
    return this.page$.asObservable();
  }
}
