import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '@so-many-ways/models';
import { UpdateUserDto } from '@so-many-ways/rest-dtos';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  patch(id: string, payload: UpdateUserDto): Observable<User> {
    return this.http.patch<User>(`/api/user`, payload, { withCredentials: true });
  }
}
