import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, model, output } from '@angular/core';

export type ToggleState = 'on' | 'off';

@Component({
  selector: 'smw-toggle',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {
  state = model<ToggleState>('off');
  changed = output<ToggleState>();

  isOn = computed(() => this.state() === 'on');

  changeState(): void {
    const state: ToggleState = this.isOn() ? 'off' : 'on';
    this.state.set(state);
    this.changed.emit(state);
  }
}
