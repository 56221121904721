import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { DurationPipe } from '@smw/front-utils';

import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'smw-card-duration',
  standalone: true,
  imports: [CommonModule, IconComponent, DurationPipe],
  template: `
    <smw-icon icon="timer"></smw-icon>
    <p class="caption-sm mb-0">{{ duration | duration }}</p>
  `,
  styleUrls: ['./card-duration.component.less'],
})
export class CardDurationComponent {
  @Input() duration!: number;
}
