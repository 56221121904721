import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { SelectUIOption } from '../input-select-ui/input-select-ui.component';

@Component({
  selector: 'smw-input-radio-ui',
  templateUrl: './input-radio-ui.component.html',
  styleUrls: ['./input-radio-ui.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputRadioUiComponent {
  @Input() answers!: SelectUIOption[];
  @Input() radioValue?: string;
  @Input() size: 'large' | 'small' = 'large';
  @Input() style?: 'survey' | 'profile' = 'survey';

  /** Form context */
  @Input() formGroup?: UntypedFormGroup;
  @Input() controlName?: string;
  /** Form context */

  @Output() radioValueChange = new EventEmitter<string>();
}
