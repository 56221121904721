import { Pipe, PipeTransform } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

import { ModeType } from '@so-many-ways/common-business';

@Pipe({
  name: 'modeTags',
  standalone: true,
})
export class ModeTagsPipe implements PipeTransform {
  transform(mode: ModeType): string[] {
    switch (mode) {
      case 'introspection':
        return [
          marker('mode-detail-introspection-hashtag-1'),
          marker('mode-detail-introspection-hashtag-2'),
          marker('mode-detail-introspection-hashtag-3'),
        ];
      case 'impact':
        return [
          marker('mode-detail-impact-hashtag-1'),
          marker('mode-detail-impact-hashtag-2'),
          marker('mode-detail-impact-hashtag-3'),
        ];
      case 'equilibre':
        return [
          marker('mode-detail-equilibre-hashtag-1'),
          marker('mode-detail-equilibre-hashtag-2'),
          marker('mode-detail-equilibre-hashtag-3'),
        ];
      case 'ascension':
        return [
          marker('mode-detail-ascension-hashtag-1'),
          marker('mode-detail-ascension-hashtag-2'),
          marker('mode-detail-ascension-hashtag-3'),
        ];
      case 'transformation':
        return [
          marker('mode-detail-transformation-hashtag-1'),
          marker('mode-detail-transformation-hashtag-2'),
          marker('mode-detail-transformation-hashtag-3'),
        ];
      default:
        return [];
    }
  }
}
