import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { AccessValidator } from './services/access-validator.service';

@Injectable({ providedIn: 'root' })
export class MobilityGuard {
  constructor(
    private router: Router,
    private accessValidator: AccessValidator,
  ) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.accessValidator.validate().pipe(
      map((canAccess) => {
        if (canAccess) {
          return true;
        }
        return this.router.createUrlTree(['/']);
      }),
    );
  }
}
