import { ChangeDetectionStrategy, Component } from '@angular/core';

import { BreakpointDirective } from '@smw/front-utils';

export const FOOTER_SELECTOR = 'smw-footer';

@Component({
  standalone: true,
  selector: FOOTER_SELECTOR,
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  imports: [BreakpointDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {}
