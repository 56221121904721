import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

import { IconCache } from './icon-cache.service';

@Injectable()
export class IconCacheInterceptor implements HttpInterceptor {
  constructor(private cache: IconCache) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.headers.get('cacheable')) {
      const cachedResponse = this.cache.get(req.urlWithParams);
      if (cachedResponse) {
        return of(cachedResponse);
      }

      return next.handle(req).pipe(
        tap((httpEvent: HttpEvent<unknown>) => {
          if (httpEvent instanceof HttpResponse) {
            this.cache.put(req.urlWithParams, httpEvent);
          }
        }),
      );
    }
    return next.handle(req);
  }
}
