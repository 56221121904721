import {
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  input,
  OnDestroy,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { Overlay } from './overlay';

/**
 * DropdownTrigger takes care of the creation of the dropdown and to add it into the DOM.
 * It also handles its positioning to always be visible in the viewport.
 */
@Directive({ selector: '[dropdownTriggerFor]', standalone: true })
export class DropdownTriggerDirective implements OnDestroy {
  dropdown = input.required<TemplateRef<void>>({ alias: 'dropdownTriggerFor' });

  private viewRef?: EmbeddedViewRef<void>;
  private dropdownElement?: HTMLElement;
  private resizeObserver?: ResizeObserver;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private vcr: ViewContainerRef,
    private renderer: Renderer2,
    private overlay: Overlay,
  ) {}

  ngOnDestroy(): void {
    this.destroyResizeObserver();
  }

  @HostListener('click')
  onClick() {
    const dropdown = this.dropdown();
    if (!dropdown) {
      return;
    }

    if (!this.viewRef || this.viewRef.destroyed) {
      this.viewRef = this.vcr.createEmbeddedView(dropdown);

      const overlay = this.overlay.create();

      this.overlay.attach(this.viewRef);

      for (const node of this.viewRef.rootNodes) {
        this.renderer.appendChild(overlay, node);
      }

      this.dropdownElement = this.viewRef.rootNodes[0];

      if (this.dropdownElement) {
        this.setElementPosition(this.dropdownElement);
        this.observeResizeOf(this.dropdownElement);
      }
    }
  }

  @HostListener('document:scroll')
  onScroll() {
    if (this.dropdownElement) {
      this.setElementPosition(this.dropdownElement);
    }
  }

  /**
   * A dropdown can be displayed :
   *  1. below the trigger (default)
   *  2. above the trigger if there is no room to open down
   */
  private setElementPosition(element: HTMLElement): void {
    const trigger = this.elementRef.nativeElement;
    const triggerRect = trigger.getBoundingClientRect();

    const elementHeight = element.offsetHeight;

    const viewportHeight = document.documentElement.offsetHeight;

    const enougthPlaceOnBottom = viewportHeight - triggerRect.bottom > elementHeight;

    this.renderer.setStyle(element, 'position', 'absolute');
    this.renderer.setStyle(element, 'left', `${triggerRect.left}px`);

    if (enougthPlaceOnBottom) {
      const bottom = triggerRect.bottom;
      this.renderer.setStyle(element, 'top', `${bottom}px`);
    } else {
      const top = triggerRect.top - element.offsetHeight;
      this.renderer.setStyle(element, 'top', `${top}px`);
    }
  }

  /**
   * When adding to the viewport, the dropdown may not have its final height, for example when the elements are loaded asynchronously.
   * In this case, the position may be off.
   * This method allows to check if there is a resize of the dropdown.
   * We only have to check once for the initial population of the dropdown, thus we unobserve as soon as the reposition is done.
   */
  private observeResizeOf(element: HTMLElement) {
    this.resizeObserver = new ResizeObserver(() => {
      this.setElementPosition(element);
      this.destroyResizeObserver();
    });

    this.resizeObserver.observe(element);
  }

  private destroyResizeObserver(): void {
    this.resizeObserver?.disconnect();
  }
}
